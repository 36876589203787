import * as preact from 'preact';
import { Fragment } from 'preact';
import { Component, h } from 'preact';
import { withText, Text } from 'preact-i18n';

import * as CardValidation from 'card-validator';
import * as TextMask from 'vanilla-text-mask';
import classnames from 'classnames';
import { autoCompleteKey } from '../../utils/dom';

import { Card } from '../../models';

interface State {
    brandIcon: string;
    cardNumberValid: boolean;
    expDateValid: boolean;
    cardCvvValid: boolean;
}

interface Props {
    onChanged: (card: Card|null) => void;
}

@withText({
    card_numbers_placeholder: 'card.card_numbers',
    exp_placeholder: 'card.exp',
    cvv_placeholder: 'card.cvv',
})
export default class CardComponent extends Component<Props, State> {
    state: State = {
        brandIcon: 'fad fa-credit-card',
        cardNumberValid: true,
        expDateValid: true,
        cardCvvValid: true,
    };
    card: Card;
    numberRef = preact.createRef();
    expDateRef = preact.createRef();
    cvvRef = preact.createRef();
    cardValidation: any = null;

    cardBrandToFa: any = {
        visa: 'fab fa-cc-visa',
        mastercard: 'fab fa-cc-mastercard',
        amex: 'fab fa-cc-amex',
        'american-express': 'fab fa-cc-amex',
        discover: 'fab fa-cc-discover',
        diners: 'fab fa-cc-diners-club',
        'diners-club': 'fab fa-cc-diners-club',
        jcb: 'fab fa-cc-jcb'
    }

    constructor(props) {
        super();

        this.card = new Card;

        this.onCardNumberChanged = this.onCardNumberChanged.bind(this);
        this.onCardExpDateChanged = this.onCardExpDateChanged.bind(this);
        this.onCardCvvChanged = this.onCardCvvChanged.bind(this);
        this.cvvInputMask = this.cvvInputMask.bind(this);
        this.cardNumberMask = this.cardNumberMask.bind(this);
    }

    componentDidMount() {
        this.initCardFields();
    }

    onCardNumberChanged(e) {
        setTimeout(() => { // Wait for Mask to update target value
            this.cardValidation = CardValidation.number(e.target.value);

            if (this.cardValidation.card) {
                this.card.type = this.cardValidation.card.type;
                this.setBrandIcon(this.cardValidation.card.type);
            }

            if (this.cardValidation.isValid) {
                this.card.number = e.target.value?.replace(/\s/g, ''); // Remove all spaces
            } else {
                this.card.number = null;
            }

            this.setState({ cardNumberValid: this.cardValidation.isValid }, () => {
                this.checkFormValidity();
            });
        }, 500);
    }

    onCardExpDateChanged(e) {
        setTimeout(() => { // Wait for Mask to update target value
            const valid = CardValidation.expirationDate(e.target.value);

            if (valid.isValid) {
                this.card.exp_month = valid.month;
                this.card.exp_year = valid.year;
            } else {
                this.card.exp_month = null;
                this.card.exp_year = null;
            }

            this.setState({ expDateValid: valid.isValid }, () => {
                this.checkFormValidity();
            });
        }, 500);
    }

    onCardCvvChanged(e) {
        setTimeout(() => { // Wait for Mask to update target value
            const valid = CardValidation.cvv(e.target.value);

            if (valid.isValid) {
                this.card.cvv = e.target.value;
            } else {
                this.card.cvv = null;
            }

            this.setState({ cardCvvValid: valid.isValid }, () => {
                this.checkFormValidity();
            });
        }, 500);
    }

    checkFormValidity() {
        const cardValid = [
            this.card.number && this.state.cardNumberValid,
            this.card.cvv && this.state.cardCvvValid,
            this.card.exp_month && this.card.exp_year && this.state.expDateValid
        ].filter(e => !e).length === 0;

        if (cardValid) {
            this.props.onChanged(this.card);
        } else {
            this.props.onChanged(null);
        }
    }

    cardNumberMask() {
        let mask = [
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/
        ];

        if (this.cardValidation?.card) {
            const gaps = this.cardValidation.card.gaps;
            const length = this.cardValidation.card.lengths.pop();

            if (length > 0) {
                for (let i = 1; i <= length; i++) {
                    mask.push(/\d/);
                    if (gaps.includes(i)) {
                        mask.push(' ');
                    }
                }
            }
        }

        return mask;
    }

    cvvInputMask() {
        let mask = [/\d/, /\d/, /\d/]; // Default mask
        if (this.cardValidation?.card) {
            const length = this.cardValidation.card.code.size;
            mask = (new Array(length)).fill(/\d/);
        }

        return mask;
    }

    initCardFields() {
        TextMask.maskInput({
            inputElement: this.numberRef.current,
            guide: false,
            mask: this.cardNumberMask
        });

        TextMask.maskInput({
            inputElement: this.expDateRef.current,
            guide: false,
            mask: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/]
        });

        TextMask.maskInput({
            inputElement: this.cvvRef.current,
            guide: false,
            mask: this.cvvInputMask
        });
    }

    setBrandIcon(brand: string) {
        this.setState({
            brandIcon: this.cardBrandToFa[brand] || 'fad fa-credit-card'
        });
    }

    render(trans) {
        return (
            <Fragment>
                <div class="form-group card-info">
                    <label for="card-number"><Text id="card.information" /></label>
                    <div class="input-group card-number-group">
                        <input id="card-number"
                            class={classnames('form-control card-number', { 'is-invalid': !this.state.cardNumberValid })}
                            autoComplete={autoCompleteKey('card-number')}
                            name={autoCompleteKey('card-number')}
                            required={true}
                            onInput={this.onCardNumberChanged}
                            ref={ this.numberRef }
                            placeholder={ trans.card_numbers_placeholder }
                            inputMode="numeric"/>
                        <div class="input-group-append">
                            <span class="input-group-text brand-icon">
                                <i class={ `fa-lg ${this.state.brandIcon}` } />
                            </span>
                        </div>

                    </div>
                    <div class="input-group">
                        <input class={classnames('form-control card-exp', { 'is-invalid': !this.state.expDateValid })}
                            autoComplete={autoCompleteKey('exp')}
                            name={autoCompleteKey('exp')}
                            required={true}
                            onInput={this.onCardExpDateChanged}
                            ref={ this.expDateRef }
                            placeholder={ trans.exp_placeholder }
                            inputMode="numeric" />
                        <input class={classnames('form-control card-cvv', { 'is-invalid': !this.state.cardCvvValid })}
                            autoComplete={autoCompleteKey('cvv')}
                            name={autoCompleteKey('cvv')}
                            required={true}
                            onInput={this.onCardCvvChanged}
                            ref={ this.cvvRef }
                            placeholder={ trans.cvv_placeholder }
                            inputMode="numeric"/>
                    </div>
                </div>
            </Fragment>
        )
    }
}
