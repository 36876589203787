import { Component, Fragment, h } from 'preact';
import { route } from 'preact-router';
import { Text, withText, MarkupText } from 'preact-i18n';
import classnames from 'classnames';
import { Subscription } from 'rxjs';
import { PhoneNumber, Transaction, PaymentIntent } from '../models';
import { TransactionService } from '../services';
import { autoCompleteKey, validateEmail } from '../utils';
import {
    makeSureTransactionWasVerified, Shared,
    MOBILE_MONEY_MODES, MOBILE_MONEY_MODES_WITHOUT_PHONE, checkIntentStatus
} from '../utils';
import PayButtonComponent from './pay-button.component';
import { Config } from '../app.configs';
import RedirectModal from '../services/redirect-modal.service';
import { TranasctionErrorComponent } from './shared/transaction-error.component';

interface State {
    transaction?: Transaction
    mode?: string;
    prefix?: string;
    phone: PhoneNumber;
    loading: boolean,
    error?: string;
    formValid: boolean;
    availableModes: string[];
    error_code;
    customer: any;
    defaultCustomer: any;
    changeBillingEmail: boolean;
    askCustomerInformation: boolean;
}

@withText({
    firstname_label: 'customer.firstname',
    firstname_placeholder: 'customer.firstname_placeholder',
    lastname_label: 'customer.lastname',
    lastname_placeholder: 'customer.lastname_placeholder',
    email_label: 'customer.email',
    email_placeholder: 'customer.email_placeholder'
})
export default class MobileMoneyComponent extends Component<any, State> {
    state: State = {
        mode: '',
        prefix: 'bj',
        phone: new PhoneNumber({ country: 'BJ' }),
        loading: false,
        formValid: false,
        availableModes: [],
        error_code: null,
        customer: {
            firstname: null,
            lastname: null,
            email: null,
        },
        defaultCustomer: {
            firstname: null,
            lastname: null,
            email: null,
        },
        changeBillingEmail: false,
        askCustomerInformation: true,
    };
    service = new TransactionService;
    token: string;
    subscription: Subscription;
    intent: PaymentIntent;

    constructor(props) {
        super();
    }

    componentDidMount() {
        this.subscription = makeSureTransactionWasVerified((verifyTrans) => {
            this.token = Shared.token;
            const transaction = verifyTrans.transaction;
            const modes = verifyTrans.getAvailableModes();
            const askCustomerInformation = verifyTrans.getAskCustomerInformationSettting();

            const availableModes = MOBILE_MONEY_MODES.filter((m) => modes.includes(m));
            const mode = transaction.mode || availableModes[0];

            this.setState({ transaction, availableModes, askCustomerInformation });

            this.filldefaultValues(transaction, mode);
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    filldefaultValues(transaction, mode) {
        let defaultCustomer = {};
        if (transaction.customer) {
            if (transaction.customer.firstname != 'Unknown') {
                defaultCustomer['firstname'] = transaction.customer.firstname;
            }

            if (transaction.customer.firstname != 'Unknown') {
                defaultCustomer['lastname'] = transaction.customer.lastname;
            }

            if (transaction.customer.email) {
                defaultCustomer['email'] = transaction.customer.email;
            }
        }

        let phone = new PhoneNumber({ country: 'BJ' });

        if (transaction.customer.phone_number) {
            phone = transaction.customer.phone_number;
        }

        this.setState({ customer: { ...defaultCustomer }, phone, defaultCustomer }, () => {
            this.modeChange(mode);
            this.checkFormValidity();
        });
    }

    modeChange(mode: string) {
        this.setState({ mode });
        Shared.paymentMode.next(mode);
        const phone = this.state.phone;

        switch (mode) {
            case 'mtn':
            case 'moov':
            case 'mtn_open':
            case 'mtn_ecw':
            case 'bmo':
            case 'sbin':
                phone.country = 'BJ';
                break;
            case 'mtn_ci':
            case 'orange_ci':
            case 'moov_ci':
            case 'wave_ci':
                phone.country = 'CI';
                break;
            case 'moov_tg':
            case 'togocel':
                phone.country = 'TG';
                break;
            case 'free_sn':
            case 'orange_sn':
            case 'wave_sn':
                phone.country = 'SN';
                break;
            case 'airtel_ne':
                phone.country = 'NE';
                break;
            case 'orange_ml':
                phone.country = 'ML';
                break;
            case 'mtn_open_gn':
                phone.country = 'GN';
            case 'orange_bf':
            case 'moov_bf':
                phone.country = 'BF';
                break;
        }
        this.setState({ phone });
    }

    hidePhone(mode: string): boolean {
        return MOBILE_MONEY_MODES_WITHOUT_PHONE.includes(mode);
    }

    handleModeChange = (e: any) => {
        const mode = e.target.value
        this.modeChange(mode);

        const phone = this.state.phone;

        this.setState({ mode, phone }, () => {
            this.checkFormValidity();
        });
    }

    updateCustomerInformation(e, key) {
        const customer = this.state.customer;
        customer[key] = e.target.value;
        this.setState({ customer: { ...customer } }, () => {
            this.checkFormValidity();
        });
    }

    customerIsValid(key) {
        const defaultCheck = typeof this.state.customer[key] === 'string' &&
            this.state.customer[key].trim() !== '';

        if (key === 'email') { // Validate email
            return defaultCheck && validateEmail(this.state.customer[key]);
        } else {
            return defaultCheck;
        }
    }

    checkFormValidity() {
        let formValid = this.checkPhoneValidity();

        if (this.state.askCustomerInformation === false) {
            this.setState({ formValid });
            return;
        }

        if (Object.keys(this.state.customer).length === 0) {
            formValid = false;
        }

        for (const key in this.state.customer) {
            if (!this.customerIsValid(key)) {
                formValid = false;
            }
        }

        this.setState({ formValid });
    }

    checkPhoneValidity() {
        if (this.hidePhone(this.state.mode)) {
            return true;
        } else {
            return this.state.phone?.isValid();
        }
    }

    handlePhoneChange = (e: any) => {
        this.state.phone.number = e.target.value;
        this.setState({ phone: this.state.phone }, () => {
            this.checkFormValidity();
        });
    }

    openDialog(id: string, url: string) {
        RedirectModal.create(`fexm-${id}`, `fexf-${id}`, url);
        RedirectModal.open(`fexm-${id}`, () => {
            this.checkStatus(this.token, this.intent.reference);
        });
    }

    renderCustomerNameFields(trans: any) {
        if (this.state.askCustomerInformation === false) {
            return <Fragment></Fragment>;
        }

        if (this.state.defaultCustomer.lastname && this.state.defaultCustomer.firstname) {
            return <Fragment></Fragment>;
        }

        // If customer has no default lastname and firstname, then aske for it
        return <div class="row">
            <div class="col-md-6 pr-3 pr-md-1">
                <div class="form-group form-group-with-label">
                    <label for="lastname">
                        {trans.lastname_label}
                    </label>
                    <input id="lastname" type="text"
                        autoComplete={autoCompleteKey('lastname')}
                        name={autoCompleteKey('lastname')}
                        value={this.state.customer.lastname}
                        required={true}
                        class={classnames('form-control', { 'is-invalid': !this.customerIsValid('lastname') })}
                        placeholder={trans.lastname_placeholder}
                        onInput={(e) => this.updateCustomerInformation(e, 'lastname')} />
                </div>
            </div>
            <div class="col-md-6 pl-3 pl-md-1">
                <div class="form-group form-group-with-label">
                    <label for="firstname">
                        {trans.firstname_label}
                    </label>
                    <input id="firstname" type="text"
                        autoComplete={autoCompleteKey('firstname')}
                        name={autoCompleteKey('firstname')}
                        value={this.state.customer.firstname}
                        required={true}
                        class={classnames('form-control', { 'is-invalid': !this.customerIsValid('firstname') })}
                        placeholder={trans.firstname_placeholder}
                        onInput={(e) => this.updateCustomerInformation(e, 'firstname')} />
                </div>
            </div>
        </div>
    }

    renderCustomerEmailField(trans: any) {
        if (!this.state.askCustomerInformation) {
            return <Fragment></Fragment>;
        }

        if (!validateEmail(this.state.defaultCustomer.email) || this.state.changeBillingEmail) {
            return this.renderEditCustomerEmailField(trans);
        } else {
            return this.renderShowCustomerEmail();
        }
    }

    renderEditCustomerEmailField(trans: any) {
        return <div class="form-group form-group-with-label">
            <label for="email">
                {trans.email_label} <span class="small text-muted">(<Text id="customer.email_description" />)</span>
            </label>
            <input type="text"
                id="email"
                autoComplete={autoCompleteKey('email')}
                name={autoCompleteKey('email')}
                required={true}
                value={this.state.customer.email}
                class={classnames('form-control', { 'is-invalid': !this.customerIsValid('email') })}
                placeholder={trans.email_placeholder}
                onInput={(e) => this.updateCustomerInformation(e, 'email')} />
        </div>
    }

    renderShowCustomerEmail() {
        return <label for="email">
            <span class="small text-muted">
                <MarkupText
                    id="customer.receipt_to"
                    fields={{ email: this.state.customer.email }}
                />
            </span><br />
            <a role="button" class="btn-link small" onClick={() => this.setState({ changeBillingEmail: true })}>
                <Text id="customer.change_email" />
            </a>
        </label>
    }

    handleSubmit = (e: any) => {
        e.preventDefault();

        if (!this.state.formValid) {
            return;
        }

        this.setState({ loading: true });
        this.service.createPayment(
            this.state.mode, this.token,
            { phone_number: this.state.phone.toJson(), customer: this.state.customer }
        )
            .then((intent: PaymentIntent) => {
                Shared.intent = intent;
                this.intent = intent;

                if (['orange_sn', 'orange_ci', 'orange_ml', 'wave_ci', 'wave_sn', 'orange_bf', 'moov_bf', 'moov_ci'].includes(this.state.mode)) {
                    this.openDialog(intent.reference, intent.metadata.payment_url);
                } else if (this.state.mode === 'bmo' && this.intent.status === 'pending') {
                    // Redirect to BMO OTP Component
                    route('/bmo', true);
                } else {
                    this.checkStatus(this.token, intent.reference);
                }
            })
            .catch(({ message, errors }) => {
                this.setState({ error: message, loading: false });
            });
    }

    public render(trans) {
        return (
            <form id="mobile-money" class="payment-container d-flex flex-column h-100" onSubmit={this.handleSubmit}>
                <fieldset class="modal-body mb-auto" disabled={this.state.loading}>
                    {
                        this.state.transaction?.customer.account_id == Config.guffeAccountId && this.state.transaction?.custom_metadata.company_name &&
                        <div class="form-group">
                            <label for="company_name"><Text id="mobile_money.company_name" /></label>
                            <div class="font-weight-bold">
                                {this.state.transaction?.custom_metadata.company_name}
                            </div>
                        </div>
                    }

                    <TranasctionErrorComponent error={this.state.error} error_code={this.state.error_code} />
                    {this.renderCustomerNameFields(trans)}
                    {this.renderCustomerEmailField(trans)}

                    <div class="form-group">
                        <label for="mode"><Text id="mobile_money.choose_operator" /></label>
                        <select class="custom-select"
                            id="mode"
                            value={this.state.mode}
                            onChange={this.handleModeChange}>
                            {
                                this.state.availableModes.map((m, k) => (
                                    <option value={m} key={k}>
                                        <Text id={`mobile_money.${m}`} />
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div class={classnames('form-group', { 'd-none': this.hidePhone(this.state.mode) })}>
                        <label for="phone_number"><Text id="mobile_money.phone_number" /></label>
                        <div class="input-group phone-number-container">
                            <div class="input-group-prepend">
                                <span class={`input-group-text ${this.checkPhoneValidity() ? '' : 'is-invalid'}`}>
                                    <i class={`flag-icon flag-icon-${this.state.phone.country.toLowerCase()}`} />
                                </span>
                            </div>
                            <input id="phone_number" required={!this.hidePhone(this.state.mode)}
                                value={this.state.phone.number}
                                onInput={this.handlePhoneChange}
                                type="text"
                                placeholder="Votre numéro de téléphone"
                                class={`form-control ${this.checkPhoneValidity() ? '' : 'is-invalid'}`} />
                        </div>
                    </div>
                    {this.state.loading &&
                        <p class="small"><Text id={`waiting_validation.${this.state.mode}`} /></p>
                    }
                </fieldset>
                <div class="modal-footer">
                    {this.state.mode &&
                        <PayButtonComponent loading={this.state.loading}
                            disabled={!this.state.formValid} />
                    }
                </div>
            </form>
        )
    }

    private checkStatus(token: string, payment_intent_reference: string, startTime: Date = new Date()) {
        checkIntentStatus(
            this.service,
            this.state.mode,
            this.token,
            this.intent.reference,
            new Date(),
            (updatedIntent: PaymentIntent | null, mess: string) => {
                this.setState({
                    error: mess,
                    error_code: updatedIntent?.last_error_code,
                    loading: false
                });
            }
        )
    }
}
